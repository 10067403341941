import React, { useState } from 'react'
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { CourseHttpService } from 'services/course';
import { Utils } from 'utils';
import { HttpService } from 'services/http';
import TranslateController from 'controller/shared/TranslateController';
import UserCourseProgress from 'view/components/course/user/UserCourseProgress';
import UserCourseNextUp from 'view/components/course/user/UserCourseNextUp';
import AdminUserCourseSummary from './AdminUserCourseSummary';
import UserStatusDot from './UserStatusDot';

function UserEnrolmentStatistics({ userId }) {

    const [data, setData] = useState(null);
    const { isLoading, error, user_courses } = useQuery(['user-enrollments'], () => CourseHttpService.loadUserEnrolments(userId), {
        onSuccess: (data) => {
            setData(data)
        }
    });

    const { _t } = TranslateController()

    return (
        <div className=''>
            <hr />
            <h3>Courses</h3>
            {isLoading && (<>Loading ...</>)}
            {data?.map((item) => (
                <div key={item.id+"ens"} className='row course-item-list mb-2'>
                    <div className='col-md-8'>
                        <div className="course-details">
                            <div className="row full-width">
                                <div className="col-6 col-md-3 p-0">
                                    <a  target='_blank' href={`/course/${item.course.id}`}>
                                        <img src={item.course.image} alt={Utils.getDefaultName(item.course)} />
                                    </a>
                                </div>
                                <div className="col-6 col-md-9">

                                    <div className="course-info mt-2">
                                        <a target='_blank' href={`/course/${item.course.id}`}>
                                            <h2 className='text-4'>{Utils.getDefaultName(item.course)}</h2>
                                        </a>
                                        <UserCourseProgress progress={item.progress ?? 0} />
                                        <small className='mt-5'> <UserStatusDot last_online={item.updated_at} /> {Utils.timeAgo(item.updated_at)}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 p-0'>
                        {!item.is_finished &&
                            <UserCourseNextUp courseId={item.course.id} content={item.next_content} />
                        }
                        {(item.is_finished && item.certificate) &&
                            <div className='enrollment-summary-certificate-container'>
                                <Link to={`/certificate/${item.certificate.uid}`} >
                                    <img src={HttpService.ApiBase + "/" + item.certificate.file_url} alt="View certificate" />
                                </Link>
                            </div>
                        }
                    </div>
                    <div className='col-md-12'>
                        <AdminUserCourseSummary userId={userId} courseId={item.course.id} />
                    </div>
                </div>
            ))}
        </div>
    )
}

export default UserEnrolmentStatistics