import React from 'react'
import { Link } from 'react-router-dom'
import { HttpService } from 'services/http'
import { Utils } from 'utils'

function EnrollmentPreview({ enrollment }) {

    return (
        <div className='text-center lan-rtl'>
            <div> <img src="/assets/images/logo.png" width="150" /> </div>
            <h1 className='mb-2'>Congratulations!</h1>
            <p className='mb-0'>{enrollment.user.profile.first_name} {enrollment.user.profile.last_name}</p>
            <p>You've completed the course:</p>

            <h2>{Utils.getDefaultName(enrollment.course)}</h2>
            <p>Date: {Utils.getDate(enrollment.completion_date)}</p>
            <div>
                <Link to={`/certificate/${enrollment.certificate.uid}`} >
                    <img src={HttpService.ApiBase + "/" + enrollment.certificate.file_url} alt="View certificate" width="200px" />
                </Link>
            </div>

        </div>
    )
}

export default EnrollmentPreview