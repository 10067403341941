import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { faBook, faPodcast, faFileAlt, faCog, faSignOutAlt, faLanguage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LanguageContext } from 'contexts/LanguageContext';
import { useQuery } from 'react-query';
import { CourseHttpService } from 'services/course';
import { Utils } from 'utils';
import { PostHttpService } from 'view/cms/features/feature_blog/services';

const MegaMenu = () => {
  const [open, setOpen] = useState(false);
  const [courses, setCourses] = useState(null);
  const [podcasts, setPodcasts] = useState(null);
  const [notations, setNotations] = useState(null);
  const [posts, setPosts] = useState(null);
  const { changeLanguage } = useContext(LanguageContext);

  const toggleMenu = () => setOpen(!open);

  // Fetch the latest items for each menu category
  const { data } = useQuery("latestCourses", () => { return CourseHttpService.loadCoursesBySize() }, {
    onSuccess: (data) => {
      if (data.results) {
        setCourses(data.results.slice(0,5));
      }
    }
  });

  const { data: pods } = useQuery("latestpodcasts", () => { return CourseHttpService.loadPodcasts() }, {
    onSuccess: (data) => {
      if (data.results) {
        setPodcasts(data.results);
      }
    }
  });

  const { data: postsData } = useQuery("latestarticles", () => { return PostHttpService.loadPosts(null, null, 5) }, {
    onSuccess: (data) => {
      if (data.results) {
        setPosts(data.results);
      }
    }
  });

  const { data: notationsData } = useQuery("latestNotations", () => { return CourseHttpService.loadNotations("", 5) }, {
    onSuccess: (data) => {
      if (data.results) {
        setNotations(data.results);
      }
    }
  });

  const courseChildren = [
    {
      title: "Categories",
      icon: "fa fa-list",
      link: "/cms/course/category",
    },
    {
      title: "Reviews",
      link: "/cms/review",
    },
    {
      title: "Levels",
      link: "/cms/levels",
    },
  ];
  const podcastChildren = [
    {
      title: "Categories",
      link: "/cms/podcast/category",
    },

  ];

  const notationChildren = [
    {
      title: "Scale",
      link: "/cms/notation/scale",
    },
    {
      title: "Time Signature",
      link: "/cms/notation/time-signature",
    },

  ];

  const articlesChildren = [
    {
      title: "Categories",
      link: "/cms/category/",
    },
  ];


  const mainMenuItems = [
    {
      title: "Courses",
      icon: "fa-list",
      link: "/cms/course/",
      childs: courseChildren,
      data: courses,
    },
    {
      title: "Podcasts",
      icon: "fa-list",
      link: "/cms/podcast/",
      childs: podcastChildren,
      data: podcasts,
    },
    {
      title: "Notations",
      icon: "fa-list",
      link: "/cms/notation/",
      childs: notationChildren,
      data: notations,
    },
    {
      title: "Articles",
      icon: "fa-list",
      link: "/cms/posts/",
      childs: articlesChildren,
      data: posts,
    },
  ];

  const secondaryMenuItems = [
    {
      title: "Languages",
      icon: 'fa fa-globe',
      children: [
        { title: "کوردی", action: (e) => {e.preventDefault(); changeLanguage("so")} },
        { title: "Kurdî", action: (e) => {e.preventDefault(); changeLanguage("kr")} },
        { title: "English", action: (e) => {e.preventDefault(); changeLanguage("en")} },
      ],
    },
    {
      title: "Settings",
      icon: 'fa fa-cog',
      link: "/cms/settings",
      children: [
        { title: "People", url: "/cms/instructor" },
        { title: "Translations", url: "/cms/translations" },
        { title: "Support", url: "/cms/support" },
      ],
    },
    
    {
      title: "Sign Out",
      icon: 'fa fa-sign-out',
      link: "/?signout",
    },

  ];

  return (
    <div className="row p-3">
      {mainMenuItems.map((item, index) => (
        <div key={index} className="col-md-3 mb-3 border-bottom">
          <Link to={item.link || "#"} className="menu-link">
            <i className={`fa ${item.icon} menu-icon mr-2`} />
            <b>{item.title}</b>
          </Link>
          {item.childs && (
            <ul className="list-unstyled pl-30">
              {item.childs.map((subItem, idx) => (
                <li key={idx + "child"}>
                  <i className={`${'fa-regular fa-circle'} mr-2 chevron-style`}></i>
                  <Link to={`${subItem.link}`} className="menu-sub-link">
                    {subItem.title}
                  </Link>
                </li>
              ))}
            </ul>
          )}
          {/* {item.data && (
            <ul className="list-unstyled pl-30">
              {item.data.map((subItem, idx) => (
                <li key={idx}>
                  <i className="fa fa-chevron-right mr-2 chevron-style"></i>
                  <a href={`${item.link}${subItem.id}`} className="menu-sub-link">
                    {subItem?.names ? Utils.getDefaultName(subItem).substring(0, 20) : subItem.title.substring(0, 20) + "..."}
                  </a>
                </li>
              ))}
            </ul>
          )} */}
        </div>
      ))}

      {secondaryMenuItems.map((item, index) => (
        <div className="col-md-3">
          <div key={index} className="mb-3">
            <a href={item.link || "#"} onClick={item.action || undefined} className="menu-link d-block">
              <i className={`fa ${item.icon} menu-icon mr-2`} />
              <b>{item.title}</b>
            </a>
            {item.children && (
              <ul className="list-unstyled pl-30">
                {item.children.map((child, idx) => (
                  <li key={idx}>
                    <i className="fa fa-chevron-right mr-2 chevron-style"></i>
                    <a
                      onClick={child.action ? child.action : null}
                      href={child.url ?? ""}
                      className={`menu-sub-link ${child.title=='کوردی' && "font-kurdi"}  cursor-pointer`}
                    >
                      {child.title}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MegaMenu;
