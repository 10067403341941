import React, { useState, useEffect } from 'react';
import { CourseHttpService } from '../../../../services/course';
import { Utils } from '../../../../utils';
import { Link } from 'react-router-dom';
import { Avatar, CircularProgress, LinearProgress } from '@mui/material';
import UserCourseProgress from 'view/components/course/user/UserCourseProgress';
import SupportMessageForm from '../forms/shared/SupportMessageForm';
import CustomModal from 'view/components/modals/CustomModal';
import UserForm from 'view/cms/features/feature_user/components/UserForm';
import EnrolledUsersFilter from '../forms/shared/EnrolledUsersFilter';
import LoadingInside from '../shared/LoadingInside';
import SelectPageSize from '../forms/shared/SelectPageSize';
import UserStatusDot from 'view/cms/features/feature_user/components/UserStatusDot';

function EnrolledUsersList({ courseId }) {
  const [data, setData] = useState(null);
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);
  const [composeModalIsOpen, setComposeModalIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userModalIsOpen, setUserModalIsOpen] = useState(false);
  const [sortBy, setSortBy] = useState('name'); // Default sort by name
  const [order, setOrder] = useState('asc'); // Default order
  const [finalQuery, setFinalQuery] = useState(''); // Default order
  
  const handlePageChange = (newPageNumber) => {
    setPageNumber(newPageNumber);
    _getData(newPageNumber);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    _getData(1);
    return () => {};
  }, [sortBy, order,pageSize]); // Re-fetch data when sorting changes

  const _getData = (pageNumber, filterQuery = "") => {
    setLoading(true);


    setFinalQuery(filterQuery);

    const sortQuery = `&size=${pageSize}&sort_by=${sortBy}&order=${order}&one_per_user=1`;
    CourseHttpService.loadEnrolments(pageNumber, courseId, filterQuery + sortQuery).then((items) => {
      if (items.results) {
        setData(items.results);
        
        if(items.results.length >0){
          setCourse(items.results[0].course)
        }

        setCount(items.count);
        setTotalPages(Math.ceil(items.count / pageSize));
      }
      setLoading(false);
    });
  };

  // Handle sorting
  const handleSort = (column) => {
    // Toggle sorting order
    const newOrder = sortBy === column && order === 'asc' ? 'desc' : 'asc';
    setSortBy(column);
    setOrder(newOrder);
  };

  return (
    <div className='box'>
      <div className="box-header text-right">
        <div><Link to={`/cms/course`}><i className="fa fa-chevron-left mr-2"></i></Link>{course && Utils.getDefaultName(course)} Members: {count}</div>
        <div className="d-flex align-items-center">
          <SelectPageSize callback={(value)=>{ setPageSize(value)  }}  /> &nbsp;&nbsp;&nbsp;
          <button className="btn btn-icon bg-success" onClick={() => { setSelectedUser(null); setComposeModalIsOpen(!composeModalIsOpen) }}><i className="fa fa-envelope "></i></button>
          
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <EnrolledUsersFilter courseId={courseId} onQuery={(query) => { _getData(1, query); }} />
        </div>
        <div className="col-md-9">
          <div className="box-body general-list">
            {loading && <LinearProgress style={{ width:"100%" }} />}
            <table className="table table-striped">
              <thead>
                <tr>
                  <th style={{ width: "50px" }}></th>
                  <th style={{ width: "300px", textAlign:"left" }}>
                    <a  className="btn p-l-0 "onClick={() => handleSort('name')}>
                      Name {sortBy === 'name' && (order === 'asc' ? '▲' : '▼')}
                    </a>
                  </th>
                  <th className='hide-on-small' style={{ width: "120px", textAlign:"left" }}>
                    <a className="btn p-l-0" onClick={() => handleSort('gender')}>
                      Gender {sortBy === 'gender' && (order === 'asc' ? '▲' : '▼')}
                    </a>
                  </th>
                  <th className='text-left'>
                    <a  className="btn p-l-0" onClick={() => handleSort('progress')}>
                      Progress {sortBy === 'progress' && (order === 'asc' ? '▲' : '▼')}
                    </a>
                  </th>
                  <th style={{ width: "100px" }}></th>
                </tr>
              </thead>
              <tbody>
                {data && data.map(item => (
                  <tr key={"people_item_" + item.id}>
                    <td><Link onClick={(e) => { e.preventDefault(); setUserModalIsOpen(item.user.profile.id) }} ><Avatar alt={item.user.profile.first_name} src={Utils.getUserImage(item.user.profile)} /></Link></td>
                    <td className='kfont'>
                      <Link onClick={(e) => { e.preventDefault(); setUserModalIsOpen(item.user.profile.id) }} >
                        <UserStatusDot last_online={item.user.last_online} /> {item.user.profile.first_name} {item.user.profile.last_name}
                        <br/>
                        <small>{Utils.getDateTime(item.user.last_online)}</small>
                      </Link>
                    </td>
                    <td className='hide-on-small'>{item.user.profile.gender == 0 ? <i className='fa fa-male icon-ma' title='Male' ></i> : item.user.profile.gender == 1 ? <i className='fa fa-female icon-fe' title='Female' ></i> : item.user.profile.gender == 2 ? "Other" : ""}</td>
                    <td title={`${item.progress} %`}>
                      <UserCourseProgress progress={item.progress ?? 0} url={`/cms/user/${item.user.id}`} />
                    </td>
                    <td className='text-right'>
                      <button className="btn btn-icon bg-primary pull-right" onClick={() => { setSelectedUser(item.user); setComposeModalIsOpen(!composeModalIsOpen) }}><i className="fa fa-envelope "></i></button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <ul className="pagination">
              <li className={`page-item ${pageNumber === 1 ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => handlePageChange(pageNumber - 1)} disabled={pageNumber === 1}>
                  <i className="fas fa-angle-left"></i>
                </button>
              </li>
              {Array.from({ length: totalPages }, (_, index) => (
                <li key={index+"pages"} className={`page-item ${pageNumber === index + 1 ? 'active' : ''}`}>
                  <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                    {index + 1}
                  </button>
                </li>
              ))}
              <li className={`page-item ${pageNumber === totalPages ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => handlePageChange(pageNumber + 1)} disabled={pageNumber === totalPages}>
                  <i className="fas fa-angle-right"></i>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <CustomModal medium key={composeModalIsOpen+"smf"} setOpen={(value) => setComposeModalIsOpen(value)}
        open={composeModalIsOpen}>

        <SupportMessageForm user={selectedUser} query={finalQuery} count={count} courseId={courseId} onDone={() => { }} />

      </CustomModal>

      <CustomModal big key={userModalIsOpen+"usm"} setOpen={(value) => setUserModalIsOpen(value)}
        open={userModalIsOpen}>
        <UserForm id={userModalIsOpen} onEditDone={() => { setUserModalIsOpen(false) }} />
      </CustomModal>

    </div>
  );
}

export default EnrolledUsersList;
