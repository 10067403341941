import React, { useState } from 'react'
import { PostHttpService } from '../services';
import { Utils } from 'utils';
import { useQuery } from 'react-query';
import CategoryForm from './forms/CategoryForm';
import CustomModal from 'view/components/modals/CustomModal';
import DeleteDialog from 'view/cms/components/shared/DeleteDialog';
import { Link } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

function CategoryList() {
    const [categories, setCategories] = useState(null);
    const [flatCategories, setFlatCategories] = useState(null);
    const [selectedId, setSelectedId] = useState(0);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = React.useState(false);
    const [refresh, setRefresh] = useState(0);

    const { isLoading, error, data, refetch } = useQuery('categories', () => { return PostHttpService.loadCategories() }, {
        onSuccess: (data) => {
            setFlatCategories(data)
            setCategories(Utils.BuildCategories(data))

        },
        refetchInterval: 60 * 10000,

        // cacheTime: 60*60*24*30*1000// Cache results for 30 days
    });



    const _handleEdit = (e, id) => {
        setSelectedId(id);
        setRefresh(refresh + 1)
        setEditModalIsOpen(true)
    }

    const _handleDelete = (e, id) => {
        setSelectedId(id);
        setRefresh(refresh + 1)
        setDeleteDialogIsOpen(true)
    }

    const _onDeleteDone = () => {
        var temp = categories.filter(item => item.id !== selectedId)
        setCategories(temp)
        setDeleteDialogIsOpen(false);
        setRefresh(refresh + 1)
    }

    const onDragEnd = (result) => {
        if (!result.destination) return; // Dropped outside the list
    
        const items = Array.from(flatCategories); // Work with the flat list
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
    
        setFlatCategories(items); // Update the flat list
        setCategories(Utils.BuildCategories(items)); // Rebuild the hierarchical structure
    
        // Prepare the sorted IDs for the backend
        const ids = items.map(item => item.id);
    
        const body = {
            ids
        };
    
        PostHttpService.saveCategorySortOrder(body).then(() => {
            console.log("Category sort order saved successfully");
        });
    };
    

    return (
        <div className='box'>
            <div className="box-header mb-5">
                <h5>Categories</h5>
                <div className="btns d-flex">

                    {/* <Link to="/cms/category/" className="btn btn-icon bg-warning mr-2" title="categories"><i className="fa fa-folder "></i></Link> */}
                    <button className="btn btn-icon bg-success" onClick={() => { setSelectedId(null); setEditModalIsOpen(true) }}><i className="fa fa-plus "></i></button>
                </div>
            </div>
            <div className="box-body general-list">
                <DragDropContext onDragEnd={onDragEnd}>

                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>
                                    #
                                </th>
                                <th> Title</th>
                                <th>Parent</th>
                                <th>Posts</th>
                                <th>Status</th>
                                <th>

                                </th>
                            </tr>
                        </thead>
                        <Droppable droppableId="categories">
                            {(provided) => (
                                <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                    {categories && categories.map((category, index) => (
                                        _categoryItem(category, index)

                                    ))}
                                    {provided.placeholder}
                                </tbody>
                            )}
                        </Droppable>

                    </table>

                </DragDropContext>

                <CustomModal big key={editModalIsOpen} setOpen={(value) => setEditModalIsOpen(value)}
                    open={editModalIsOpen} >

                    <CategoryForm id={selectedId} onEditDone={() => { refetch(); setEditModalIsOpen(false) }} />

                </CustomModal>


                <DeleteDialog
                    url={`/postapi/category/${selectedId}`}
                    isOpen={deleteDialogIsOpen}
                    key={"mdl" + deleteDialogIsOpen + selectedId}
                    setOpen={setDeleteDialogIsOpen}
                    notifyDone={_onDeleteDone}
                />
            </div>
        </div>
    )

    function _categoryItem(category, index,level=0) {
        return <Draggable key={"cat" + category.id} draggableId={"cat" + category.id} index={index}>
            {(provided) => (
                <React.Fragment key={category.id}>
                    <tr
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <td>{category.id}</td>
                        <td className={`kfont ${!category.parent ? 'font-bold' : ''}`}>
                            
                            <Link onClick={(e) => { _handleEdit(e, category.id); }}>
                                {category.parent && (<i className={`fa fa-chevron-right font-chevron mr-2 ${level<2 ? 'ml-2' : 'ml-3'}`}></i>)}
                                 {Utils.getDefaultName(category)}
                            </Link></td>
                        <td className='kfont'>{category.parent && Utils.getDefaultName(category.parent)}</td>
                        <td></td>
                        <td>{category.status ? <> <i className="fa fa-check color-success"></i> </> : <><i className="fa fa-remove color-danger"></i></>}</td>
                        {/* <td>{Utils.getDate(category.created_at)}</td> */}
                        <td className='text-right'>
                            <a href="#" onClick={(e) => { _handleDelete(e, category.id); }} className="btn btn-outline   btn-swap-1">
                                <span><i className="fas fa-trash color-gray"></i></span>
                                <span>Delete <i className="fas fa-remove ms-2"></i></span>
                            </a>
                        </td>
                    </tr>
                    {category.childs && category.childs.length > 0 && (
                        category.childs.map((child, childIndex) => (
                            _categoryItem(child, `${index}-${childIndex}`,level+1) 
                        ))
                    )}
                </React.Fragment>
            )}
        </Draggable>;
    }
}

export default CategoryList