import React, { useContext, useEffect, useState } from 'react'
import { Utils, translate } from '../../../utils'
import PageLayout from 'view/components/shared/PageLayout'
import NotationsGrid from 'view/components/course/components/NotationsGrid'
import TranslateController from 'controller/shared/TranslateController'
import CustomModal from 'view/components/modals/CustomModal'
import SelectInstructorList from 'view/cms/components/lists/SelectInstructorList'
import { useParams } from 'react-router-dom'
import { CourseHttpService } from 'services/course'

function Notations() {

    const [query, setQuery] = useState()
    const [searchQuery, setSearchQuery] = useState()
    const { _t } = TranslateController();

    const [advancedSearch, setAdvancedSearch] = useState({
        name: "",
        time_signature: "",
        scale: "",
        level: "",
        artists: "",
        writers: "",
    });
    const [selectedArtists, setSelectedArtists] = useState([]);
    const [selectedWriters, setSelectedWriters] = useState([]);
    const [artistModalIsOpen, setArtistModalIsOpen] = useState(false);
    const [writerModalIsOpen, setWriterModalIsOpen] = useState(false);
    const { artist_id } = useParams();
    const [instructor, setInstructor] = useState()
    const [count, setCount] = useState()

    
    useEffect(() => {
        if (artist_id) {
          CourseHttpService.loadInstructor(artist_id).then(item => {
            setInstructor(item)
          })
        }
      }, [])

    // const [selectedStartLetter, setSelectedStartLetter] = useState(null)
    // const alphabet = Array.from(Array(26)).map((_, i) => String.fromCharCode(65 + i));

    // const handleAdvancedSearchChange = (e) => {
    //     const { name, value } = e.target;
    //     setAdvancedSearch(prevState => ({
    //         ...prevState,
    //         [name]: value
    //     }));
    // };

    const handleInstructorSelect = (field, instructor) => {
        if (field === 'artist') {
            setSelectedArtists(prevSelectedArtists => [...prevSelectedArtists, instructor]);
            setAdvancedSearch(prevState => ({
                ...prevState,
                artists: [...(prevState.artists || []), instructor.id]
            }));
        } else if (field === 'writer') {
            setSelectedWriters(prevSelectedWriters => [...prevSelectedWriters, instructor]);
            setAdvancedSearch(prevState => ({
                ...prevState,
                writers: [...(prevState.writers || []), instructor.id]
            }));
        }
    };

    const buildQueryString = () => {
        let queryString = `?size=100`;
        for (const key in advancedSearch) {
            if (advancedSearch[key]) {
                queryString += `&${key}=${advancedSearch[key]}`;
            }
        }
        return queryString;
    };




    return (
        <PageLayout showbreadcrumbs={false} HeaderChild={<HeaderChild count={count} query={query} title={instructor && Utils.getDefaultName(instructor)} />}>
            {artistModalIsOpen && (
                <CustomModal big dark className="" key={artistModalIsOpen} setOpen={(value) => setArtistModalIsOpen(value)} open={artistModalIsOpen}>
                    <SelectInstructorList type={"artist"} showImage={true} notationId={null} OnSelect={(instructor) => { handleInstructorSelect('artist', instructor); setArtistModalIsOpen(false); }} />
                </CustomModal>
            )}

            {writerModalIsOpen && (
                <CustomModal big dark className="" key={writerModalIsOpen} setOpen={(value) => setWriterModalIsOpen(value)} open={writerModalIsOpen}>
                    <SelectInstructorList type={"writer"} showImage={true} notationId={null} OnSelect={(instructor) => { handleInstructorSelect('writer', instructor); setWriterModalIsOpen(false); }} />
                </CustomModal>
            )}



            <NotationsGrid query={searchQuery} setResultCount={(count)=>{ setCount(count) }} />
        </PageLayout>


    )
}



function HeaderChild({ count=10 , title }) {
    const { _t } = TranslateController();


    return (
        <>
            <h1 className="text-color-light pt-3 pb-2 font-weight-bold text-10 text-sm-8">{title ?? _t("Notations")}</h1>
            <p>{count}  {_t("notes")}</p>

        </>
    );
}

export default Notations