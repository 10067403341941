import React, { useState } from 'react'
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Utils } from 'utils';
import { PostHttpService } from 'view/cms/features/feature_blog/services';

function BlogMenu({ setSearchQuery }) {
    const [categories, setCategories] = useState(null);
    const [query, setQuery] = useState(null);

    const { isLoading, error, data, refetch } = useQuery('menu_categories', () => { return PostHttpService.loadCategories() }, {
        onSuccess: (data) => {
            setCategories(BuildCategories(data))
        },
        refetchInterval: 60 * 10000,

        // cacheTime: 60*60*24*30*1000// Cache results for 30 days
    });

    const BuildCategories = (categories) => {

        if (categories != null) {

            var parents = [];

            parents = categories?.filter(x => x.menu && x.status);

            parents.forEach(parent => {
                parent.childs = Utils.getChilds(categories, parent.id, 1)
            })

            return parents;
        }

    }


    return (
        <header id="header" data-plugin-options="{'stickyEnabled': false}">
            <div className="border-color-light border-top-0 box-shadow-none">
                <div className="header-nav-bar bg-blog-menu pb-5px" >
                    <div className="container">
                        <div className="header-row rtl">
                            <div className="header-column">
                                <form className='full-width' action="" onSubmit={(e) => { e.preventDefault(); setSearchQuery(query) }}>
                                    <div className="search-container justify-content-start">
                                        <div className="simple-search blog-search input-group mt-3 mt-small-0 mb-4">
                                            <input className="form-control text-1 text-light kfont" value={query} name="q" onChange={(e) => { setQuery(e.target.value) }} type="search" placeholder="Search..." />
                                            <button className="btn" type="submit" aria-label="Search">
                                                <i className="fas fa-search header-nav-top-icon"></i>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="header-column hide-on-small">
                                <div className="header-row justify-content-start header-rtl">
                                    <div className="header-nav header-nav-force-dark-text justify-content-start py-2 py-lg-3" >
                                        <div className="header-nav-main header-nav-rtl header-nav-main-effect-1 header-nav-main-sub-effect-1">
                                            <nav className="collapse">
                                                <ul className="nav nav-pills rtl pr-0" id="mainNav">
                                                    <li className="dropdown dropdown-full-color dropdown-dark">
                                                        <Link to={`/blog/`} className="dropdown-item  kfont">
                                                            <i className="fa fa-home"></i>
                                                        </Link>
                                                    </li>
                                                    {categories && categories.map(category => (
                                                        <li key={`bcat${category.id}`} className="dropdown dropdown-full-color dropdown-dark">
                                                            <Link to={`/blog/${category.id}`} className="dropdown-item  kfont">
                                                                <i className="fas fa-chevron-down menu-chevron-down" ></i>
                                                                {Utils.getDefaultName(category)}
                                                            </Link>

                                                            {(category.childs && category.childs.length > 0) && (
                                                                <ul className="dropdown-menu">
                                                                    {category.childs.map(child => (
                                                                        <>
                                                                            {(child.childs && child.childs.length > 0) ? (
                                                                                <li class="dropdown-submenu">
                                                                                    <Link to={`/blog/${child.id}`} class="dropdown-item kfont">{Utils.getDefaultName(child)}</Link>
                                                                                    <ul class="dropdown-menu">
                                                                                        {child.childs.map(grand_child => (
                                                                                            <li><Link to={`/blog/${grand_child.id}`} class="dropdown-item kfont" >{Utils.getDefaultName(grand_child)}</Link></li>

                                                                                        ))}
                                                                                    </ul>
                                                                                </li>
                                                                            ) : (
                                                                                <li>
                                                                                    <Link to={`/blog/${child.id}`} className="dropdown-item kfont" >
                                                                                        {Utils.getDefaultName(child)}
                                                                                    </Link>
                                                                                </li>
                                                                            )}
                                                                        </>
                                                                    ))}

                                                                </ul>
                                                            )}

                                                        </li>
                                                    ))}


                                                </ul>
                                            </nav>
                                        </div>
                                        <button className="btn header-btn-collapse-nav my-2" data-bs-toggle="collapse" data-bs-target=".header-nav-main nav">
                                            <i className="fas fa-bars"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div >
            </div >
        </header >

    )
}

export default BlogMenu