import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PageLayout from 'view/components/shared/PageLayout'
import BlogStyle1 from '../components/blog_styles/BlogStyle1'
import BlogStyle2 from '../components/blog_styles/BlogStyle2'
import BlogStyle3 from '../components/blog_styles/BlogStyle3'
import PostsAside from '../components/PostsAside'
import { LanguageContext } from 'contexts/LanguageContext'
import { Utils, translate } from 'utils'
import { useQuery } from 'react-query'
import { PostHttpService } from 'view/cms/features/feature_blog/services'
import BlogStyle6 from '../components/blog_styles/BlogStyle6'
import TranslateController from 'controller/shared/TranslateController'
import BlogMenu from '../components/BlogMenu'

function Blog() {

	var { category_id , keyword } = useParams();
	const { _t } = TranslateController()


    if(!category_id) category_id = 0;
    const [query, setQuery] = useState()
    const [blogCategories, setBlogCategories] = useState()

    const { isLoading, error, data, refetch } = useQuery(['categories_featured'], () => { return PostHttpService.loadFeaturedCategories() }, {
        onSuccess: (data) => {
            console.log("datadatadatadatadata")
            console.log(data)
            setBlogCategories(data)
        },
        refetchInterval: 60 * 100000,

        // cacheTime: 60*60*24*30*1000// Cache results for 30 days
    });

    // useEffect(() => {
    //     refetch()
    // }, [category_id])


    


    return (
        <PageLayout hasPadding={false} className='p-0' showbreadcrumbs={false} HeaderChild={<HeaderChild category_id={category_id} query={query} setSearchQuery={setQuery} />}>
            
            <BlogMenu  setSearchQuery={setQuery} />
            
            <div className='pt-5 p-small-0 p-3'>
                <BlogStyle1 categoryId={category_id} query={query} />
            </div>
            <div className="pb-1 mt-3 pt-2 lan-rtl p-3">
                {blogCategories && blogCategories.map(category => (
                    <BlogStyle6 key={`blog_cat_${category.id}`} categoryId={category.id} size={4} category={category} />
                )
                )}
                <div className="col-md-12">
                    <div className="row pb-1 pt-3">
                        <div className="col-md-6">
                            <BlogStyle3 title={_t("Articles")} categoryId={25} size={6} />
                        </div>
                        {/* <div className="col-md-4">
                            <BlogStyle3 title={_t("Poem and Story")} categoryId={24} size={6}  />
                        </div> */}
                        <div className="col-md-6">
                            <BlogStyle3 title={_t("Music")} categoryId={10} size={6} />
                        </div>
                    </div>
                </div>
            </div>
        </PageLayout>
    )
}



function HeaderChild({category_id, setSearchQuery }) {
    const { translations } = useContext(LanguageContext);
    const _t = (key) => {
        return translate(key, translations)
    }

    const [category, setCategory] = useState(null)

    // useEffect(() => {
    //     _getCategory(category_id)
    // }, [category_id])


    // const _getCategory =(category_id) =>{
    //     PostHttpService.loadCategory(category_id).then((category) => {
    //         console.log(category)
    //         setCategory(category)
    //     });
    // }

    const [query, setQuery] = useState("")


    return (
        <>

            {/* <h1 className="text-color-light pt-3 pb-3 font-weight-bold text-6">{(category && category?.names) ? Utils.getDefaultName(category) :_t("Articles")}</h1> */}
            {/* <form action="" onSubmit={(e) => { e.preventDefault(); setSearchQuery(query) }}>
                <div className="search-container">
                    <div className="simple-search input-group mt-3 mb-4">
                        <input className="form-control text-1 text-light" value={query} name="q" onChange={(e) => { setQuery(e.target.value) }} type="search" placeholder="Search..." />
                        <button className="btn" type="submit" aria-label="Search">
                            <i className="fas fa-search header-nav-top-icon"></i>
                        </button>
                    </div>
                </div>
            </form> */}
        </>

    )
}

export default Blog