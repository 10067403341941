import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Utils } from 'utils'

function CourseListTable({ data, handleDelete, showDelete }) {

  var navigate = useNavigate()

  const location = useLocation();
  const getQueryParams = (query) => new URLSearchParams(query);
  const queryParams = getQueryParams(location.search);
  const level = queryParams.get('level');
  const category = queryParams.get('category');

  return (
    <div>
      <table className="table table-striped">
        <thead>
          <tr>
            <th style={{ width: 30 }}>#</th>
            <th style={{ width: 400 }}>Course</th>
            <th>Enrolled</th>
            <th>Level {level && <span className='ml-2'><a href="/cms/course">X</a></span>}</th>
            <th>Category {category && <span className='ml-2'><a href="/cms/course">X</a></span>}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data?.map(item => (
            <tr key={item.id}>
              <td>
                <Link to={`/cms/course/${item.id}`} className="">
                  {item.id}
                </Link>
              </td>
              <td className='kfont'>
                <Link to={`/cms/course/${item.id}`} className="">
                  {Utils.getDefaultName(item)}
                </Link>
              </td>
              <td>
                <Link to={`/cms/course/${item.id}/enrolled`} className="">
                  <i className="fa fa-user"></i> {item.enrolled_count}
                </Link>
              </td>
              <td className='kfont'>
                {item.level && (<a href={`/cms/course/?level=${item.level.id}`}>{Utils.getDefaultName(item.level)}</a>)}
              </td>
              <td className='kfont'>
                {item.category && (<a href={`/cms/course/?category=${item.category.id}`}>{Utils.getDefaultName(item.category)}</a>) }
              </td>
              <td>
                {showDelete && (
                  <button className="btn" onClick={() => handleDelete(item.id)}>
                    <i className="fa fa-trash"></i>
                  </button>
                )}

              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default CourseListTable