import React, { useState } from 'react'
import Gallery from './Gallery'

function ImageFieldWithGallery({onImageSelected,defaultImageUrl=null,className="",type=""}) {

    const [gallery_modal_is_open, setGalleryModalIsOpen] = useState(false);
  const [image_url, setImageUrl] = useState(defaultImageUrl);

    const openGallery = () => {

        setGalleryModalIsOpen(true)
    
      }
    
      const closeGallery = () => {
        setGalleryModalIsOpen(false)
      }
    
      const setSelectedImageGallery = (gallery_item) => {
        setImageUrl(gallery_item?.image)
        onImageSelected(gallery_item?.image)
      }
    
  return (
    <>
        <Gallery
        type={type}
        setSelectedGalleryItem={setSelectedImageGallery}
        isOpen={gallery_modal_is_open}
        key={gallery_modal_is_open}
        closeGallery={() => {
          closeGallery();
        }}
      />
        <img
                className={`img upload-img mb-3 full-width ${className}`}
                onClick={openGallery}
                src={
                  image_url
                    ? image_url
                    : "/assets/images/default.png"
                }
                alt=""
              />
    </>
  )
}

export default ImageFieldWithGallery