import React, { useRef } from 'react';
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Link, useParams } from "react-router-dom";
import { CourseHttpService } from '../../../../services/course';
import { Utils } from '../../../../utils';
import CustomModal from '../../../components/modals/CustomModal';
import InstructorForm from '../forms/course/InstructorForm';
import DeleteDialog from '../shared/DeleteDialog';
import SortModal from '../Widgets/shared/sort/SortModal';
import { DragHandle, Grid3x3, List } from '@mui/icons-material';
import SearchQuery from '../forms/shared/SearchQuery';
import { Avatar, Button, CircularProgress, Grid } from '@mui/material';
import TranslateController from 'controller/shared/TranslateController';
import LoadingInside from '../shared/LoadingInside';

function InstructorList({ size = 60 }) {

  const [items, setData] = useState(null);
  const [searchQuery, setSearchQuery] = useState(`&size=${size}&page=1&type=artist`);
  const [activeTab, setActiveTab] = useState('artist'); // State to manage active tab
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [sortModalIsOpen, setSortModalIsOpen] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [showAsList, setShowAsList] = useState(0);

  const [selectedId, setSelectedId] = useState(0);
  const { id } = useParams();
  const [refresh, setRefresh] = useState(0);

  const { _t } = TranslateController();

  const loadingMore = useRef(false);

  const { isLoading, error, data, refetch } = useQuery(['instructors', searchQuery], () => {
    return CourseHttpService.searchInstructorsBySearchQuery(searchQuery);
  }, {
    onSuccess: (newData) => {

      if(loadingMore.current){
        setData(prevData => {
          const existingIds = new Set((prevData || []).map(item => item.id));
          // Filter out the new data that already exists in the current data
          const filteredNewData = newData.results.filter(item => !existingIds.has(item.id));
          // Append only the unique items to the existing list
          return [...(prevData || []), ...filteredNewData];
        });
        loadingMore.current = false;
      }
      else{
        setData(newData.results)
      }
      
      setCount(newData.count)
      setTotalPages(Math.ceil(newData.count / size));
    }
  });

  useEffect(() => {
    refetch()

    return () => {
    }
  }, [searchQuery, pageNumber])



  const _handleDelete = (e, id) => {
    setSelectedId(id);
    setRefresh(refresh + 1)
    setDeleteDialogIsOpen(true)
  }

  const _onDeleteDone = () => {
    var temp = items.filter(item => item.id !== selectedId)
    setData(temp)
    setDeleteDialogIsOpen(false);
    setRefresh(refresh + 1)
  }

  const _edit = (e, item) => {
    e.preventDefault();
    setSelectedId(item.id);
    setEditModalIsOpen(true)
    setRefresh(refresh + 1)
  }

  const handleTabClick = (type) => {
    setData([])
    setActiveTab(type);
    setPageNumber(1)
    setSearchQuery(`&type=${type}&size=${size}&page=${1}`);
  }

  const types = [
    { label: "Artist", value: "artist" },
    { label: "Author", value: "author" },
    { label: "Course Instructor", value: "instructor" },
    { label: "Transcriber", value: "transcriber" },
    { label: "Podcast Writer", value: "writer" },
    { label: "Narrator", value: "narrator" },
    { label: "Poet", value: "poet" },
  ];

  const loadMore = () => {
    if (pageNumber < totalPages) {
      loadingMore.current = true;
      setSearchQuery(`&type=${activeTab}&size=${size}&page=${pageNumber + 1}`);
      setPageNumber(prev => prev + 1);  // Increment the page number
    }
  };

  return (
    <div className='box'>
      <div className="box-header mb-5">
        <h5>People</h5>
        <div className="btns box-header-btns">
          <SearchQuery onQuery={(query) => {
            let q = `&query=${query}&type=${activeTab}&size=${size}&page=${1}`;
            setSearchQuery(q);
          }} query={""} />
          <button className="btn btn-icon" title="Sort people" onClick={() => { setSelectedId(null); setSortModalIsOpen(true) }}><DragHandle /></button>
          <button className="btn btn-icon" title="Change view" onClick={() => { setShowAsList(!showAsList);  }}>{showAsList ? (<Grid3x3 />) : (<List />) }</button>
          <button className="btn btn-icon" onClick={() => { setSelectedId(null); setEditModalIsOpen(true) }}><i className="fa fa-plus "></i></button>
        </div>
      </div>

      <div className="tabs tabs-top mt-3 full-width">
        <ul className="nav nav-tabs nav-tabs-custom">
          {types.map((type, index) => (
            <li className="nav-item" key={`type` + index}>
              <a className={`nav-link ${activeTab === type.value ? 'active' : ''}`} href={`#${type.value}`} data-bs-toggle="tab" role="tab" onClick={() => handleTabClick(type.value)}>{_t(type.label)}</a>
            </li>
          ))}

        </ul>
      </div>

      <div className="box-body d-flex flex-wrap position-relative">
        {isLoading && (
          <CircularProgress size={12} color="inherit" />
        )}
        {(showAsList == 1) && (
          <table className="table table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th></th>
                <th>Name</th>
                {/* <th className='hide-on-small'>Type</th> */}
                <th className='hide-on-small'>Date </th>
                <th className='hide-on-small'> </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {items && items.map(item => (
                <tr key={"people_item_" + item.id}>
                  <td>{item.id}</td>
                  <td><Link onClick={(e) => { _edit(e, item) }}><Avatar alt={Utils.getDefaultName(item)} src={Utils.getUserImage(item)} /></Link></td>
                  <td className='kfont'>
                    <Link onClick={(e) => { _edit(e, item) }}>
                      {Utils.getDefaultName(item)}
                    </Link>
                  </td>
                  {/* <td className=''>{item.type}</td> */}
                  <td className='hide-on-small'>{Utils.getDate(item.created_at)} <br /> <small title='Last login'>{item.last_login && Utils.getDate(item.last_login)}</small> </td>
                  <td className='hide-on-small'>
                    {item.report.podcasts_count > 0 && (
                      <span className="badge bg-dark--100 font-weight-normal text-2 badge-sm anim-hover-translate-top-5px transition-2ms me-2 mb-2" title="Number of podcasts">
                        <i className="fas fa-podcast me-1"></i>{item.report.podcasts_count}
                      </span>
                    )}
                    {item.count_of_posts > 0 && (
                      <span className="badge bg-dark--100 font-weight-normal text-2 badge-sm anim-hover-translate-top-5px transition-2ms me-2 mb-2" title="Number of posts">
                        <i className="fas fa-file-alt me-1"></i>{item.count_of_posts}
                      </span>
                    )}
                    {item.report.notations_count > 0 && (
                      <span className="badge bg-dark--100 font-weight-normal text-2 badge-sm anim-hover-translate-top-5px transition-2ms me-2 mb-2" title="Number of notations">
                        <i className="fas fa-music me-1"></i>{item.report.notations_count}
                      </span>
                    )}
                    {item.report.courses_count > 0 && (
                      <span className="badge bg-dark--100 font-weight-normal text-2 badge-sm anim-hover-translate-top-5px transition-2ms me-2 mb-2" title="Number of courses">
                        <i className="fas fa-book me-1"></i>{item.report.courses_count}
                      </span>
                    )}
                  </td>

                  <td className='text-right'>
                    <a href="#" onClick={(e) => { _handleDelete(e, item.id) }} className="btn btn-outline btn-swap-1">
                      <span><i className="fas fa-trash color-gray "></i></span>
                      <span>Delete <i className="fas fa-trash ms-2 color-gray "></i></span>
                    </a>
                  </td>
                </tr>
              ))}

              {pageNumber < totalPages && (
                <tr className="load-more">
                  <td colSpan={6} className='text-center'>
                    {isLoading && (
                      <CircularProgress size={12} color="inherit" />
                    )}
                    {!isLoading && (
                      <Button variant="outlined" onClick={loadMore} disabled={isLoading}>Load More</Button>
                    )}

                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}


        {(showAsList == 0) && (
          <>
            <div className="row py-3 row-cols-md-6 ">
              {items?.map((instructor, i) => (
                <div className="col-6 mb-4 mb-md-3 " data-appear-animation="fadeIn" data-appear-animation-delay={i * 250} key={"ins" + instructor.id}>
                  <Link onClick={(e) => { _edit(e, instructor) }}>
                    <div className="instructor-grid-item text-center">
                      <img className="img-fluid img-sm-85p mb-2" src={Utils.getArtistImage(instructor)} alt={Utils.getDefaultName(instructor)} />
                      <h3 className="kfont text-color-dark text-2 mb-0 ">{Utils.getDefaultName(instructor)}</h3>

                    </div>
                  </Link>

                </div>
              ))}
            </div>
            {pageNumber < totalPages && (
              <div  className='full-width text-center mb-3'>
                {isLoading && (
                  <CircularProgress size={12} color="inherit" />
                )}
                {!isLoading && (
                  <Button variant="outlined" onClick={loadMore} disabled={isLoading}>Load More</Button>
                )}

              </div>
            )}
          </>
        )}
      </div>

      <CustomModal className="" big key={editModalIsOpen} setOpen={(value) => setEditModalIsOpen(value)} open={editModalIsOpen}>
        <InstructorForm id={selectedId} onEditDone={() => { refetch() }} />
      </CustomModal>

      <DeleteDialog
        url={`/academy/course/instructor/${selectedId}`}
        isOpen={deleteDialogIsOpen}
        key={"mdl" + deleteDialogIsOpen + selectedId}
        setOpen={setDeleteDialogIsOpen}
        notifyDone={_onDeleteDone}
      />

      <SortModal type="instructor" data={items} setData={setData} isOpen={sortModalIsOpen} setIsOpen={setSortModalIsOpen} />
    </div>
  )
}

export default InstructorList;
