import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { Utils } from 'utils';
import { PostHttpService } from 'view/cms/features/feature_blog/services';

function BlogStyle6({ categoryId, query, keyword = "", category, size = 40 }) {


    const [posts, setPosts] = useState(null);


    const { isLoading, error, data, refetch } = useQuery(['posts', categoryId, keyword], () => { return PostHttpService.loadPosts(categoryId, query, size, 1, keyword) }, {
        onSuccess: (data) => {
            setPosts(data.results)
        },
        // cacheTime: 60*60*24*30*1000// Cache results for 30 days
        refetchInterval: 600 * 10000,
    });

    useEffect(() => {
        refetch()
    }, [query])




    return (
        <div className="row lan-rtl mb-3">
            {category && (
                
                <Link to={`/blog/${category.id}`} className="heading heading-border heading-middle-border">
                    <h3 className="text-4"><strong className="font-weight-bold text-1 px-3 text-light py-2 bg-quaternary kfont">{category && Utils.getDefaultName(category)}</strong></h3>
                    {/* <div className="categories-prev-next">
                        <div className="previous">
                            <i className="fa fa-chevron-left"></i>
                            <i className="fa fa-chevron-left"></i>
                        </div>
                        <div className="next">
                            <i className="fa fa-chevron-left"></i>
                        </div>
                    </div> */}
                </Link>
            )}

            {posts && posts.map((post, index) => (
                <div key={`blog_post_${post.id}`} className="col-6 col-md-3 col-lg-3 pb-2">
                    <Link to={`/post/${post.id}/${post.slug}`}>
                        <article>
                            <div className="thumb-info thumb-info-no-borders thumb-info-bottom-info thumb-info-bottom-info-dark thumb-info-bottom-info-show-more thumb-info-no-zoom border-radius-0">
                                <div className="thumb-info-wrapper thumb-info-wrapper-opacity-6">
                                    <img src={post.image} className="img-fluid book-list-image" alt={post.title} />
                                    <div className="thumb-info-title bg-transparent p-4 lan-text-right">
                                        <div className="thumb-info-inner mt-1">
                                            <h2 className="text-color-light line-height-2 text-4 font-weight-bold mb-0 lan-rtl lan-text-right">{post.title}</h2>
                                        </div>
                                        <div className="thumb-info-show-more-content">
                                            <p className="mb-0  line-height-normal text-size-12 mb-1 mt-2 text-light opacity-5 lan-kfont lan-text-right line-height-normal">{Utils.parse(post.short_description, 15)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </Link>
                </div>
            )
            )}
        </div>
    )
}

export default BlogStyle6