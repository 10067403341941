import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, TextField } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { Edit } from "@mui/icons-material";
import { PostHttpService } from "../../services";
import Done from "view/cms/components/shared/Done";
import Error from "view/cms/components/shared/Error";
import LoadingInside from "view/cms/components/shared/LoadingInside";
import SelectStatus from "./SelectStatus";
import SelectCategory from "./SelectCategory";
import NamesWidgets from "view/cms/components/forms/shared/NamesWidgets";

function CategoryForm({ id, ...props }) {
  const { register, handleSubmit, setValue, watch, formState: { errors }, } = useForm();
  const [editingItem, setEditingItem] = useState(null);

  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [item_image, setImage] = useState(null);
  const [data, setData] = useState(null);
  const [names, setNames] = useState([]);
  const [namesRefresh, setNamesRefresh] = useState(0);



  useEffect(() => {
    if (id) {
      PostHttpService.loadCategory(id).then(item => {
        setData(item)
        onEditing(item)
      })
    }
  }, [])

  const onEditing = (item) => {
    setEditingItem(item)


    for (const key in item) {
      setValue(key, item[key])
    }

    if (item.parent) {
      setValue("parent", item.parent.id)
    }

    setNames(item.names)

    setNamesRefresh(refresh + 1)
    setImage(item.image)
  }

  const saveChanges = (data) => {

    setError(false)
    if (editingItem) data.id = editingItem.id;

    var body = {
      "id": id ? id : data.id,
      "names": names,
      "parent": data.parent,
      "status": data.status ?? true,
      "menu": data.menu ?? false,
      "featured": data.featured ?? false,
      "sortorder": data.sortorder ?? 1,
    }


    setDone(false)
    setLoading(true)
    PostHttpService.saveCategory(body).then(response => {
      setLoading(false)
      setDone(true)
      setError(false)
      props.onEditDone(response);
    }).catch(err => {
      setLoading(false)
      // setError("Something went wrong")
    });
  };

  const handleValue = (value, name) => {
    switch (name) {

      case "status":
        setValue("status", value)
        break;
      case "parent":
        setValue("parent", value)
        break;
      case "menu":
        setValue("menu", value)
        break;
      case "featured":
        setValue("featured", value)
        break;
      default:
        break;
    }
  }



  return (
    <div>


      <form className="mt-4" onSubmit={handleSubmit(saveChanges)}>
        <div className="row">
          <div className="col-md-8">
            <div className="form-group">
              <NamesWidgets show_short_description={1} key={namesRefresh + "nms"} names={names} setNames={(names) => { setNames(names) }} />
            </div>
            <hr className="solid" />
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <SelectCategory placeholder="Parent" width={250} name="parent" callback={handleValue} value={watch("parent")} border={"1"} />
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="">Status</label>
                <SelectStatus placeholder="Status" width={250} callback={handleValue} value={watch("status") ? 1 : 0} border={"1"} />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="">Featured</label>
                <SelectStatus placeholder="Featured" name="featured" width={250} callback={handleValue} value={watch("featured") ? 1 : 0} border={"1"} />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="">Menu</label>
                <SelectStatus placeholder="Menu" name="menu" width={250} callback={handleValue} value={watch("menu") ? 1 : 0} border={"1"} />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group kfont">
                <TextField
                  fullWidth
                  className="kfont"
                  label="Sort Order"
                  value={watch('sortorder') ?? ""}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  {...register("sortorder")}
                />
              </div>
            </div>

          </div>


        </div>
        <div className="form-group mt-3 text-right mb-1">
          <Button
            onClick={handleSubmit}
            type="submit"
            variant="contained"
            startIcon={editingItem ? <Edit /> : <AddIcon />}
          >
            {editingItem ? "Save Changes" : "Add Category"}
          </Button>


        </div>
        <div className="form-group">
          {done && <Done />}
          {error && <Error message={error} />}
          {loading && <LoadingInside />}
        </div>
      </form>
    </div>
  );
}

export default CategoryForm;
