import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { CourseHttpService } from '../../../services/course'
import Footer from '../../components/shared/Footer'
import Header from '../../components/shared/Header'
import PageHeader from 'view/components/shared/PageHeader'
import CourseLecturesList from 'view/components/course/week/CourseLecturesList'
import { LanguageContext } from 'contexts/LanguageContext'
import { Utils, translate } from 'utils'
import InstructorItem from 'view/components/course/instructor/InstructorItem'
import InstructorItemSmall from 'view/components/course/instructor/InstructorItemSmall'
import { AutoStoriesOutlined, PlayCircleOutline, QuizOutlined } from '@mui/icons-material'

function CourseLectures() {
	const { id } = useParams();
	const [course, setCourse] = useState(null);

	const { isLoading, error, refetch, data } = useQuery(['course', id], () => { return CourseHttpService.loadCourse(id) }, {
		onSuccess: (data) => {

			setCourse(data)
			// Utils.initiateTheme()
		}
	});


	const { translations } = useContext(LanguageContext);
	const _t = (key) => {
		return translate(key, translations)
	}



	useEffect(() => {
		// Utils.initiateTheme()
		// alert();
		refetch();
	}, [id, refetch])
	return (
		<div className="body">
			<Header dark={1} />

			<div role="main" className="main">
				<PageHeader child={<HeaderChild course={course} _t={_t} />} showbreadcrumbs={false} />
				<div id='main'>
					<div className='custom-page-content p-relative z-index-2'>
						<div className="container custom-padding-top-1 custom-padding-bottom-1 px-lg-5 bg-light">
							<div className="pb-4 mb-4 mt-3 ">
								{/* <div className="col-md-2 mx-md-auto p-0 ">
										<CourseSummaryAside course={id} />
									</div> */}
								{isLoading && (<>Loading ...</>)}
								{!isLoading && (<CourseLecturesList key={1} course={id} />)}
							</div>
						</div>
					</div>
				</div>

			</div>

			<Footer />
		</div>


	)
}

function HeaderChild({ _t, course }) {
	return (
		<div className='row mt-5' >

			<div className="col-md-6">

				<div className='d-flex flex-column flex-start'>

					<ul className="breadcrumb font-weight-semibold text-4 kfont negative-ls-1 hide-on-small">
						<li className=''><a href="/explore">{_t("Browse")}</a></li>
						<li className="text-color-primary"><a href="/courses/">{_t("Courses")}</a></li>
						{/* <li className="active text-gray">Piano</li> */}
					</ul>

					<h1 className="text-color-light text-left text-small-center font-weight-bold text-10 mb-3">{course && Utils.getDefaultName(course)}</h1>

					{(course && course.level) && (
						<p class="text-2 text-left font-weight-light level-intro mb-3 hide-on-small">{(_t("Level :"))} {Utils.getDefaultName(course.level)}</p>

					)}

					<div className="row mb-3 lectures-header-info hide-on-small lan-kfont lan-rtl">
						<div className='col-4  text-5-6 mb-2' title={`${course?.course_report?.Video?.remaining_count ?? 0}` +" "+ _t("left")}>
							{/* <PlayCircleOutline className='mr-1' /> */}
							<img src="/assets/images/svg/play-circle.svg" width={30} alt="" />
							{true ? <>
								&nbsp;{course?.course_report?.Video?.total_count ?? 0} <span className=""></span>
							</> : "All videos completed"}

						</div>
						<div className='col-4  text-5-6 mb-2' title={`${course?.course_report?.Reading?.remaining_count ?? 0}` +" "+ _t("left")}>

							{/* <AutoStoriesOutlined className='mr-1' /> */}
							<img src="/assets/images/svg/book.svg" width={30} alt="" />
							{true ? <>
								&nbsp;{course?.course_report?.Reading?.total_count ?? 0}  <span className=""></span>
							</> : "All readings completed"}
						</div>
						{(course?.course_report?.Quiz?.remaining_count != null &&
							course?.course_report?.Quiz?.remaining_count > 0) && (
								<div className='col-4  text-5-6 mb-2'>
									{/* <QuizOutlined className='mr-1' /> */}
									<img src="/assets/images/svg/quiz.svg" width={30} alt="" />
									{true ? <>
										&nbsp;{course?.course_report?.Quiz?.remaining_count ?? 0} <span className=""></span>
									</> : "All assesments completed"}
								</div>
							)}

					</div>

					<div class="font-weight-light hide-on-small opacity-8 pb-2 mb-2 text-light text-justify kfont">

						{course && course?.instructors.map((instructor) => (
							<p key={`ins_name_${instructor.id}`} >{(_t("Instructor:"))} {course && Utils.getDefaultName(instructor)}</p>
						)
						)}
					</div>


				</div>
			</div>
			<div className="col-md-6 text-right">
				{course && course?.instructors.map((instructor) => (
					<InstructorItemSmall instructor={instructor} key={`instructors_${instructor.id}`} />
				)
				)}
			</div>
		</div>

	)
}

export default CourseLectures