import React from 'react'
import { Link } from 'react-router-dom'
import { Utils } from '../../../../utils'

function InstructorItemSmall({ instructor }) {
    return (
        <div className="instructor-item-small" key={`instructor ${instructor.id}`}>
            <div className="ins-img">
                <Link to={`/instructor/${instructor.id}`} className="">
                    <img src={Utils.getImage(instructor)} title={Utils.getDefaultName(instructor)} alt={Utils.getDefaultName(instructor)} />
                </Link>
            </div>
        </div>
    )
}

export default InstructorItemSmall