import ContentVideoController from 'controller/course/content/ContentVideoController';
import VideoQuestionIndicators from './question/VideoQuestionIndicators';
import VideoQuestion from './question/VideoQuestion';
import { useEffect } from 'react';
import NextContent from 'view/components/course/content/NextContent';
import VideoTracks from './VideoTracks';
import { HttpService } from 'services/http';
import CertificatePreview from 'view/components/course/user/CertificatePreview';
import CustomModal from 'view/components/modals/CustomModal';
import EnrollmentPreview from 'view/components/course/enrollment/EnrollmentPreview';

const ContentVideo = ({ content, videoUrl, onProgress, poster, currentTime, ...props }) => {


  const { currentSubtitle, videoRef, showNextDialog, handleVideoStart,
    handleVideoEnd, setShowNextDialog, videoLoaded, question, setQuestion, enrollment,enrollmentmodalIsOpen,setEnrollmentModalIsOpen } = ContentVideoController({ content: content, currentTime: currentTime, onProgress: onProgress })


  useEffect(() => {
    const handleContextmenu = e => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }
  }, [])

  const prependWebsiteUrl = (url) => {
    const websiteUrl = HttpService.SiteBase; // Replace with your actual website URL
    if (url.includes("uploaded_media") && !url.startsWith("https:")) {
      return `${websiteUrl}/${url}`;
    }
    return url;
  };


  return (
    <div className='watch-video-container mb-4 mt-3'>

      {/* {content.youtube_url && (<YouTubeEmbed videoId={Utils.getYoutubeId(content.youtube_url)} />) } */}

      <video ref={videoRef}
        controlsList='nodownload'
        onPlay={handleVideoStart} autoPlay={true} poster={poster} onEnded={handleVideoEnd} currenttime={currentTime} controls >
        <source src={prependWebsiteUrl(videoUrl)} type="video/mp4" />
        {content && (
          <VideoTracks tracks={content?.tracks} />
        )}

      </video>



      {/* {currentSubtitle && <div className="transcript-on-video">
        {currentSubtitle.text}
      </div>} */}

      {videoLoaded && (
        <VideoQuestionIndicators videoElement={videoRef.current} content={content} onQuestionClick={setQuestion} />
      )}





      {question && <VideoQuestion question={question} onClose={() => { setQuestion(null); if (videoRef.current) videoRef.current.play() }} />}

      {showNextDialog &&
        <NextContent content={content} onBack={setShowNextDialog} />
      }

      {enrollmentmodalIsOpen && (
        <CustomModal medium key={enrollmentmodalIsOpen+"emio"} setOpen={(value) => setEnrollmentModalIsOpen(value)}
          open={enrollmentmodalIsOpen} >
          <EnrollmentPreview enrollment={enrollment} />
        </CustomModal>
      )}


    </div>
  );
};

export default ContentVideo;