import React from 'react'
import { Link,  useLocation,  useNavigate } from 'react-router-dom'
import { Utils } from 'utils'

function PodcastListTable({ data, handleDelete, showDelete }) {

  var navigate = useNavigate()

  const location = useLocation();
  const getQueryParams = (query) => new URLSearchParams(query);
  const queryParams = getQueryParams(location.search);
  const writer = queryParams.get('writer');
  const narrator = queryParams.get('narrator');

  return (
    <div>
      <table className="table table-striped">
        <thead>
          <tr>
            <th style={{ width: 30 }}>#</th>
            <th style={{ width: 400 }}>Podcast</th>
            <th >Writer {writer && <span className='ml-2'><a href="/cms/podcast">X</a></span>}</th>
            <th >Narrator {narrator && <span className='ml-2'><a href="/cms/podcast">X</a></span>}</th>
            <th >Episodes</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data?.map(item => (
            <tr key={item.id}>
              <td>
                <Link to={`/cms/podcast/${item.id}`} className="">
                  {item.id}
                </Link>
              </td>
              <td>
                <Link to={`/cms/podcast/${item.id}`} className="">
                  {Utils.getDefaultName(item)}
                </Link>
              </td>
              <td>
                {item.writers && item.writers.length > 0 && (
                  <>
                    <a href={`/cms/podcast/?writer=${item.writers[0]?.id}`}>
                      {item.writers && item.writers.length > 0 && Utils.getDefaultName(item.writers[0])}
                    </a>
                  </>
                )}

              </td>
              <td>
                {item.narrators && item.narrators.length > 0 && (
                  <>
                    <a href={`/cms/podcast/?narrator=${item.narrators[0]?.id}`}>
                      {item.narrators && item.narrators.length > 0 && Utils.getDefaultName(item.narrators[0])}
                    </a>
                  </>
                )}
              </td>
              <td>
                    {item.episodes_count}
              </td>
              <td>
                {showDelete && (
                  <button className="btn" onClick={() => handleDelete(item.id)}>
                    <i className="fa fa-trash"></i>
                  </button>
                )}

              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default PodcastListTable