import React, { useEffect, useState } from 'react'
import { PostHttpService } from '../services';
import { Utils } from 'utils';
import { useQuery } from 'react-query';
import CustomModal from 'view/components/modals/CustomModal';
import DeleteDialog from 'view/cms/components/shared/DeleteDialog';
import PostForm from './forms/PostForm';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import SearchQuery from 'view/cms/components/forms/shared/SearchQuery';

function PostsList() {
    const [posts, setPosts] = useState(null);
    const [refresh, setRefresh] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedId, setSelectedId] = useState(0);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [count, setCount] = useState(1);
    const location = useLocation();
    const navigate = useNavigate();
    const getQueryParams = (query) => new URLSearchParams(query);

    const { id } = useParams();


    const queryParams = getQueryParams(location.search);
    const category = queryParams.get('category');
    const author = queryParams.get('author');
    const status = queryParams.get('status');
    const language = queryParams.get('language');

    const { isLoading, error, data, refetch } = useQuery(['posts', searchQuery, category, author, status,language, pageNumber], () => {
        let finalQuery = `?query=${searchQuery}`;

        if (category) finalQuery += `&category=${category}`;
        if (author) finalQuery += `&author=${author}`;
        if (status) finalQuery += `&status=${status}`;
        if (language) finalQuery += `&language=${language}`;

        return PostHttpService.searchPosts(finalQuery, pageNumber);
    }, {
        onSuccess: (data) => {
            setPosts(data.results);
            setCount(data.count);
            setTotalPages(Math.ceil(data.count / 50));
        },
    });

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [selectedId]);


    useEffect(() => {
        if(id){
            setSelectedId(id);
            setRefresh(refresh + 1);
            setEditModalIsOpen(true);
        }
    }, [id]);

    const handleEdit = (e, id) => {
        setSelectedId(id);
        setRefresh(refresh + 1);
        setEditModalIsOpen(true);
    };

    const handleDelete = (e, id) => {
        setSelectedId(id);
        setRefresh(refresh + 1);
        setDeleteDialogIsOpen(true);
    };

    const onDeleteDone = () => {
        const temp = posts.filter(item => item.id !== selectedId);
        setPosts(temp);
        setDeleteDialogIsOpen(false);
        setRefresh(refresh + 1);
    };

    const handlePageChange = (newPageNumber) => {
        setPageNumber(newPageNumber);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    return (
        <div className='box'>
            <div className="box-header mb-5">
                <h5>Posts</h5>
                <div className="btns box-header-btns d-flex align-items-center">
                    Count : {count}
                    <SearchQuery onQuery={(query) => setSearchQuery(query)} query={searchQuery} />
                    <button className="btn btn-icon bg-success" onClick={() => { setSelectedId(null); setEditModalIsOpen(!editModalIsOpen) }}><i className="fa fa-plus "></i></button>
                </div>
            </div>
            <div className="box-body general-list">
                {editModalIsOpen && (
                    <div className={`row full-width`}>
                        <div className="col-md-12">
                            <PostForm key={selectedId + "pf"} id={selectedId} onEditDone={() => { refetch(); setEditModalIsOpen(false) }} />
                            <hr />
                        </div>
                    </div>
                )}
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Title</th>
                            {/* <th>Slug</th> */}
                            <th>Category {category && <span className='ml-2'><a href="/cms/posts">X</a></span>}</th>
                            <th>Author {author && <span className='ml-2'><a href="/cms/posts">X</a></span>}</th>
                            <th>Language {language && <span className='ml-2'><a href="/cms/posts">X</a></span>}</th>
                            {/* <th>Status {status && <span className='ml-2'><a href="/cms/posts">X</a></span>}</th> */}
                            <th>Date</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {posts && posts.map(item => (
                            <tr key={"cat" + item.id}>
                                <td>{item.id}</td>
                                <td className='kfont'><Link onClick={(e) => { handleEdit(e, item.id) }}>{item.title}</Link></td>
                                {/* <td className='kfont'><Link onClick={(e) => { handleEdit(e, item.id) }}>{item.slug}</Link></td> */}
                                <td className='kfont'><a href={`/cms/posts/?category=${item.category?.id}`}>{item.category && Utils.getDefaultName(item.category)}</a></td>
                                <td className='kfont'><a href={`/cms/posts/?author=${item.author?.id}`}>{item.author && Utils.getDefaultName(item.author)}</a></td>
                                <td className='kfont'><a href={`/cms/posts/?language=${item.language?.id}`}>{item.language && item.language.name}</a></td>
                                {/* <td>{item.status ? <><i className="fa fa-check color-success"></i></> : <><i className="fa fa-remove color-danger"></i></>}</td> */}
                                <td>{Utils.getShortDate(item.created_at)}</td>
                                <td className='text-right'>
                                    <a href="#" onClick={(e) => { handleDelete(e, item.id) }} className="btn btn-outline btn-swap-1">
                                        <span><i className="fas fa-trash color-gray "></i></span>
                                        <span>Delete <i className="fas fa-trash ms-2 color-gray "></i></span>
                                    </a>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <ul className="pagination float-end">
                    <li className={`page-item ${pageNumber === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageChange(pageNumber - 1)} disabled={pageNumber === 1}>
                            <i className="fas fa-angle-left"></i>
                        </button>
                    </li>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <li key={index} className={`page-item ${pageNumber === index + 1 ? 'active' : ''}`}>
                            <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                                {index + 1}
                            </button>
                        </li>
                    ))}
                    <li className={`page-item ${pageNumber === totalPages ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageChange(pageNumber + 1)} disabled={pageNumber === totalPages}>
                            <i className="fas fa-angle-right"></i>
                        </button>
                    </li>
                </ul>
            </div>

            <DeleteDialog
                url={`/postapi/post/${selectedId}`}
                isOpen={deleteDialogIsOpen}
                key={"mdl" + deleteDialogIsOpen + selectedId}
                setOpen={setDeleteDialogIsOpen}
                notifyDone={onDeleteDone}
            />
        </div>
    );
}

export default PostsList;
